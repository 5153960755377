import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Form from "./form"
import { ReactSVG } from "react-svg"

import { font, color, ease } from "../../../layout/global"

import smoothscroll from "smoothscroll-polyfill"
import ArrowLeft from "../../../images/configurador/arrow-left.svg"
import ArrowRight from "../../../images/configurador/arrow-right.svg"

import { ConsentGtag } from "../../../utils/consentGtag"

if (typeof window !== `undefined`) {
  smoothscroll.polyfill()
}

const Configurador = ({ data, dataForm, strings }) => {
  let image0 = data?.image0.childImageSharp.fluid
  let image1 = data?.image1.childImageSharp.fluid
  let image2 = data?.image2.childImageSharp.fluid
  let image3 = data?.image3.childImageSharp.fluid
  let image4 = data?.image4.childImageSharp.fluid
  let image5 = data?.image5.childImageSharp.fluid
  let image6 = data?.image6.childImageSharp.fluid
  let image7 = data?.image7.childImageSharp.fluid
  let image8 = data?.image8.childImageSharp.fluid
  let image0M = data?.image0M.childImageSharp.fluid
  let image1M = data?.image1M.childImageSharp.fluid
  let image2M = data?.image2M.childImageSharp.fluid
  let image3M = data?.image3M.childImageSharp.fluid
  let image4M = data?.image4M.childImageSharp.fluid
  let image5M = data?.image5M.childImageSharp.fluid
  let image6M = data?.image6M.childImageSharp.fluid
  let image7M = data?.image7M.childImageSharp.fluid
  let image8M = data?.image8M.childImageSharp.fluid

  const [selectedOption, setSelectedOption] = useState(["", "", "", "", "", "", "", ""])
  const [selectedValue, setSelectedValue] = useState(["", "", "", "", "", "", "", ""])
  const [disabledOption, setDisabledOption] = useState([false, true, true, true, true, true, true, true])
  const [optionsFilter, setOptionsFilter] = useState([1, 1, 1, 1, 1, 1, 1, 1])
  const [hiddenWrapper, setHiddenWrapper] = useState([false, false, false, false, false, false, false, false])
  const [hasChanged, setHasChanged] = useState(false)
  const [showForm, setShowForm] = useState(false)

  const img0Ref = useRef(),
    img1Ref = useRef(),
    img2Ref = useRef(),
    img3Ref = useRef(),
    img4Ref = useRef(),
    img5Ref = useRef(),
    img6Ref = useRef(),
    img7Ref = useRef(),
    img8Ref = useRef(),
    img0MRef = useRef(),
    img1MRef = useRef(),
    img2MRef = useRef(),
    img3MRef = useRef(),
    img4MRef = useRef(),
    img5MRef = useRef(),
    img6MRef = useRef(),
    img7MRef = useRef(),
    img8MRef = useRef(),
    imgFinalRef = useRef(),
    imgFinalMobileRef = useRef(),
    backgroundRef = useRef(),
    backgroundMRef = useRef(),
    submitRef = useRef(),
    warningRef = useRef()

  const onUpdateItem = index => e => {
    warningRef.current.style.visibility = "hidden"

    // Ao selecionar uma opção, esconder o form se ele estiver visível - visto já não estar com as mesmas opções selecionadas
    setShowForm(false)
    // Selected Option
    let newArray = [...selectedOption]
    newArray.forEach((ele, i) => {
      if (i === index) {
        newArray[i] = e.target.options[e.target.selectedIndex].text
      } else if (i > index) {
        newArray[i] = ""
        document.querySelector(`#input${i}`).selectedIndex = 0
      }
    })
    setSelectedOption(newArray)

    // Disabled Options
    let newArray2 = [...disabledOption]
    newArray2.forEach((ele, i) => {
      if (i === index && index !== 7) {
        return (newArray2[i + 1] = false)
      } else if (i !== index && i - 1 !== index && i > index) {
        return (newArray2[i] = true)
      }
    })
    setDisabledOption(newArray2)

    // Value
    let newArray3 = [...selectedValue]
    newArray3.forEach((ele, i) => {
      if (i < index) {
        return newArray3[i]
      } else if (i > index) {
        return (newArray3[i] = "")
      } else if (i === index && e.target.value === "") {
        return (newArray3[i] = "")
      } else {
        return (newArray3[i] = Number(e.target.value) + 1)
      }
    })
    setSelectedValue(newArray3)
    setHasChanged(true)
  }

  const handleReset = () => {
    // Esconde o aviso de campos incompletos
    warningRef.current.style.visibility = "hidden"

    // Reset aos estados de opção selecionada, opções disabled e valores das opções selecionadas
    let newArray = ["", "", "", "", "", "", "", ""]
    setSelectedOption(newArray)
    let newArray2 = [false, true, true, true, true, true, true, true]
    setDisabledOption(newArray2)
    let newArray3 = ["", "", "", "", "", "", "", ""]
    setSelectedValue(newArray3)
    let newArray4 = [1, 1, 1, 1, 1, 1, 1, 1]
    setOptionsFilter(newArray4)

    // Todos os campos voltam a estar visíveis
    setHiddenWrapper([false, false, false, false, false, false, false, false])

    // Esconde o formulário e dá reset no configurador-form
    setShowForm(false)
    document.getElementById("turboram-form").reset()

    // Reset às imagens de fundo e svgs finais
    backgroundRef.current.classList.remove("final")
    backgroundMRef.current.classList.remove("final")
    submitRef.current.classList.remove("final")
    img0Ref.current && (img0Ref.current.style.opacity = 1)
    img1Ref.current && (img1Ref.current.style.opacity = 0)
    img2Ref.current && (img2Ref.current.style.opacity = 0)
    img3Ref.current && (img3Ref.current.style.opacity = 0)
    img4Ref.current && (img4Ref.current.style.opacity = 0)
    img5Ref.current && (img5Ref.current.style.opacity = 0)
    img6Ref.current && (img6Ref.current.style.opacity = 0)
    img7Ref.current && (img7Ref.current.style.opacity = 0)
    img8Ref.current && (img8Ref.current.style.opacity = 0)
    img0MRef.current && (img0MRef.current.style.opacity = 1)
    img1MRef.current && (img1MRef.current.style.opacity = 0)
    img2MRef.current && (img2MRef.current.style.opacity = 0)
    img3MRef.current && (img3MRef.current.style.opacity = 0)
    img4MRef.current && (img4MRef.current.style.opacity = 0)
    img5MRef.current && (img5MRef.current.style.opacity = 0)
    img6MRef.current && (img6MRef.current.style.opacity = 0)
    img7MRef.current && (img7MRef.current.style.opacity = 0)
    img8MRef.current && (img8MRef.current.style.opacity = 0)
    imgFinalRef.current && (imgFinalRef.current.style.opacity = 0)
    imgFinalMobileRef.current && (imgFinalMobileRef.current.style.opacity = 0)
  }

  const handleSubmit = () => {
    // Verifica se os campos estão todos preenchidos
    ConsentGtag()
    if (selectedValue.every(v => v !== "")) {
      // Ao submeter, os campos ficam disabled para que não seja possível alterar as opções
      let newArray = [true, true, true, true, true, true, true, true]
      setDisabledOption(newArray)
      setShowForm(true)
      submitRef.current.classList.add("final")

      // Desce o scroll para o formulário
      setTimeout(() => {
        let element = document.querySelector(`#contact-form`)
        if (element) {
          let offset = 50
          let bodyRect = document.body.getBoundingClientRect().top
          let elementRect = element.getBoundingClientRect().top
          let elementPosition = elementRect - bodyRect
          let offsetPosition = elementPosition - offset
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          })
        }
      }, 150)
    } else {
      // Se os campos não estiverem preenchidos, aparece um aviso
      warningRef.current.style.visibility = "visible"
    }
  }

  // Contexto passado para o formulário final
  const allStates = {
    option1: selectedOption[0],
    option2: selectedOption[1],
    option3: selectedOption[2],
    option4: selectedOption[3],
    option5: selectedOption[4],
    option6: selectedOption[5],
    option7: selectedOption[6],
    option8: selectedOption[7],
    label1: data.form1.title,
    label2: data.form2.title,
    label3: data.form3.title,
    label4: data.form4.title,
    label5: data.form5.title,
    label6: data.form6.title,
    label7: data.form7.title,
    label8: data.form8.title,
    value1: selectedValue[0],
    value2: selectedValue[1],
    value3: selectedValue[2],
    value4: selectedValue[3],
    value5: selectedValue[4],
    value6: selectedValue[5],
    value7: selectedValue[6],
    value8: selectedValue[7],
    oven: "Turboram",
    title: data.title,
    subtitle: data.subtitle,
    update: handleReset,
  }

  // Helper que modifica as possíveis opções em cada campo consoante opções selecionadas anteriores
  // Prev -> Previous state
  // n -> index no array do state
  // x -> novo valor para fazer push no index n
  const changeState = (prev, n, x) => {
    let state = prev.map((item, i) => {
      if (i === n) {
        return x
      } else {
        return item
      }
    })
    return state
  }

  // Alterar opções conseguintes e imagens de fundo consoante opções selecionadas
  useEffect(() => {
    if (hasChanged) {
      // Segundo Passo - Dimensão de tabuleiros
      switch (selectedValue[0]) {
        case 1:
          setOptionsFilter(prev => changeState(prev, 1, 1))
          setHiddenWrapper([false, false, false, false, false, false, false, false])
          break
        case 2:
          setOptionsFilter(prev => changeState(prev, 1, 2))
          setHiddenWrapper([false, false, false, false, false, false, true, false])
          setSelectedValue(prev => changeState(prev, 6, 1))
          setSelectedOption(prev => changeState(prev, 6, `${document.querySelector("#input6").options[1].text}`))
          break
        case 3:
          setOptionsFilter(prev => changeState(prev, 1, 3))
          setSelectedValue(prev => changeState(prev, 3, 1))
          setSelectedValue(prev => changeState(prev, 4, 1))
          setSelectedValue(prev => changeState(prev, 6, 1))
          setSelectedValue(prev => changeState(prev, 7, 1))
          setSelectedOption(prev => changeState(prev, 3, `${document.querySelector("#input3").options[1].text}`))
          setSelectedOption(prev => changeState(prev, 4, `${document.querySelector("#input4").options[1].text}`))
          setSelectedOption(prev => changeState(prev, 6, `${document.querySelector("#input6").options[1].text}`))
          setSelectedOption(prev => changeState(prev, 7, `${document.querySelector("#input7").options[1].text}`))
          setHiddenWrapper([false, false, false, true, true, false, true, true])
          break
        default:
          break
      }
      // Terceiro Passo
      switch (selectedValue[1]) {
        case 1:
        case 2:
          switch (selectedValue[0]) {
            case 1:
              setOptionsFilter(prev => changeState(prev, 2, 1))
              break
            case 2:
              setOptionsFilter(prev => changeState(prev, 2, 3))
              break
            case 3:
              setOptionsFilter(prev => changeState(prev, 2, 5))
              break
            default:
              break
          }
          break
        case 3:
          switch (selectedValue[0]) {
            case 1:
              setOptionsFilter(prev => changeState(prev, 2, 2))
              break
            case 2:
              setOptionsFilter(prev => changeState(prev, 2, 4))
              break
            case 3:
              setOptionsFilter(prev => changeState(prev, 2, 6))
              break
            default:
              break
          }
          break
        case 4:
          switch (selectedValue[0]) {
            case 1:
              setOptionsFilter(prev => changeState(prev, 2, 2))
              break
            case 2:
              setOptionsFilter(prev => changeState(prev, 2, 4))
              break
            case 3:
              setOptionsFilter(prev => changeState(prev, 2, 5))
              break
            default:
              break
          }
          break
        default:
          break
      }
      // Quarto Passo - Tipologia de Fecho
      // Aqui apenas o XL não é aplicável, logo podemos ver qual o forno escolhido no primeiro passo
      switch (selectedValue[0]) {
        case 1:
        case 2:
          setOptionsFilter(prev => changeState(prev, 3, 1))
          break
        case 3:
          setOptionsFilter(prev => changeState(prev, 3, 2))
          break
        default:
          break
      }
      // Quinto Passo - Autolavagem
      switch (selectedValue[0]) {
        case 1:
          switch (selectedValue[3]) {
            case 1:
              setOptionsFilter(prev => changeState(prev, 4, 1))
              setHiddenWrapper([false, false, false, false, false, false, false, false])
              break
            case 2:
              setOptionsFilter(prev => changeState(prev, 4, 2))
              setSelectedValue(prev => changeState(prev, 4, 1))
              setSelectedOption(prev => changeState(prev, 4, `${document.querySelector("#input4").options[1].text}`))
              setHiddenWrapper([false, false, false, false, true, false, false, false])
              break
            default:
              break
          }
          break
        case 2:
          switch (selectedValue[3]) {
            case 1:
              setOptionsFilter(prev => changeState(prev, 4, 1))
              setHiddenWrapper([false, false, false, false, false, false, true, false])
              break
            case 2:
              setOptionsFilter(prev => changeState(prev, 4, 2))
              setSelectedValue(prev => changeState(prev, 4, 1))
              setSelectedOption(prev => changeState(prev, 4, `${document.querySelector("#input4").options[1].text}`))
              setHiddenWrapper([false, false, false, false, true, false, true, false])
              break
            default:
              break
          }
          break
        case 3:
          setOptionsFilter(prev => changeState(prev, 4, 3))
          break
        default:
          break
      }
      // Sétimo Passo - Base
      switch (selectedValue[0]) {
        case 1:
          setOptionsFilter(prev => changeState(prev, 6, 1))
          break
        case 2:
        case 3:
          setOptionsFilter(prev => changeState(prev, 6, 2))
          break
        default:
          break
      }
      // Oitavo Passo - Extras
      switch (selectedValue[0]) {
        case 1:
          setOptionsFilter(prev => changeState(prev, 7, 1))
          break
        case 2:
          setOptionsFilter(prev => changeState(prev, 7, 2))
          break
        case 3:
          setOptionsFilter(prev => changeState(prev, 7, 3))
          break
        default:
          break
      }

      // Imagens
      switch (selectedValue[0]) {
        case 1:
          img0Ref.current && (img0Ref.current.style.opacity = 0)
          img1Ref.current && (img1Ref.current.style.opacity = 1)
          img2Ref.current && (img2Ref.current.style.opacity = 0)
          img3Ref.current && (img3Ref.current.style.opacity = 0)
          img4Ref.current && (img4Ref.current.style.opacity = 0)
          img5Ref.current && (img5Ref.current.style.opacity = 0)
          img6Ref.current && (img6Ref.current.style.opacity = 0)
          img7Ref.current && (img7Ref.current.style.opacity = 0)
          img8Ref.current && (img8Ref.current.style.opacity = 0)
          imgFinalRef.current && (imgFinalRef.current.style.opacity = 0)
          imgFinalMobileRef.current && (imgFinalMobileRef.current.style.opacity = 0)
          img0MRef.current && (img0MRef.current.style.opacity = 0)
          img1MRef.current && (img1MRef.current.style.opacity = 1)
          img2MRef.current && (img2MRef.current.style.opacity = 0)
          img3MRef.current && (img3MRef.current.style.opacity = 0)
          img4MRef.current && (img4MRef.current.style.opacity = 0)
          img5MRef.current && (img5MRef.current.style.opacity = 0)
          img6MRef.current && (img6MRef.current.style.opacity = 0)
          img7MRef.current && (img7MRef.current.style.opacity = 0)
          img8MRef.current && (img8MRef.current.style.opacity = 0)
          break
        case 2:
          img0Ref.current && (img0Ref.current.style.opacity = 0)
          img1Ref.current && (img1Ref.current.style.opacity = 0)
          img2Ref.current && (img2Ref.current.style.opacity = 0)
          img3Ref.current && (img3Ref.current.style.opacity = 0)
          img4Ref.current && (img4Ref.current.style.opacity = 1)
          img5Ref.current && (img5Ref.current.style.opacity = 0)
          img6Ref.current && (img6Ref.current.style.opacity = 0)
          img7Ref.current && (img7Ref.current.style.opacity = 0)
          img8Ref.current && (img8Ref.current.style.opacity = 0)
          imgFinalRef.current && (imgFinalRef.current.style.opacity = 0)
          imgFinalMobileRef.current && (imgFinalMobileRef.current.style.opacity = 0)
          img0MRef.current && (img0MRef.current.style.opacity = 0)
          img1MRef.current && (img1MRef.current.style.opacity = 0)
          img2MRef.current && (img2MRef.current.style.opacity = 0)
          img3MRef.current && (img3MRef.current.style.opacity = 0)
          img4MRef.current && (img4MRef.current.style.opacity = 1)
          img5MRef.current && (img5MRef.current.style.opacity = 0)
          img6MRef.current && (img6MRef.current.style.opacity = 0)
          img7MRef.current && (img7MRef.current.style.opacity = 0)
          img8MRef.current && (img8MRef.current.style.opacity = 0)
          break
        case 3:
          img0Ref.current && (img0Ref.current.style.opacity = 0)
          img1Ref.current && (img1Ref.current.style.opacity = 0)
          img2Ref.current && (img2Ref.current.style.opacity = 0)
          img3Ref.current && (img3Ref.current.style.opacity = 0)
          img4Ref.current && (img4Ref.current.style.opacity = 0)
          img5Ref.current && (img5Ref.current.style.opacity = 0)
          img6Ref.current && (img6Ref.current.style.opacity = 0)
          img7Ref.current && (img7Ref.current.style.opacity = 1)
          img8Ref.current && (img8Ref.current.style.opacity = 0)
          imgFinalRef.current && (imgFinalRef.current.style.opacity = 0)
          imgFinalMobileRef.current && (imgFinalMobileRef.current.style.opacity = 0)
          img0MRef.current && (img0MRef.current.style.opacity = 0)
          img1MRef.current && (img1MRef.current.style.opacity = 0)
          img2MRef.current && (img2MRef.current.style.opacity = 0)
          img3MRef.current && (img3MRef.current.style.opacity = 0)
          img4MRef.current && (img4MRef.current.style.opacity = 0)
          img5MRef.current && (img5MRef.current.style.opacity = 0)
          img6MRef.current && (img6MRef.current.style.opacity = 0)
          img7MRef.current && (img7MRef.current.style.opacity = 1)
          img8MRef.current && (img8MRef.current.style.opacity = 0)
          break
        default:
          break
      }
      if (selectedValue[0] === 1 && selectedValue[3] === 1) {
        img0Ref.current && (img0Ref.current.style.opacity = 0)
        img1Ref.current && (img1Ref.current.style.opacity = 0)
        img2Ref.current && (img2Ref.current.style.opacity = 1)
        img3Ref.current && (img3Ref.current.style.opacity = 0)
        img4Ref.current && (img4Ref.current.style.opacity = 0)
        img5Ref.current && (img5Ref.current.style.opacity = 0)
        img6Ref.current && (img6Ref.current.style.opacity = 0)
        img7Ref.current && (img7Ref.current.style.opacity = 0)
        img8Ref.current && (img8Ref.current.style.opacity = 0)
        imgFinalRef.current && (imgFinalRef.current.style.opacity = 0)
        imgFinalMobileRef.current && (imgFinalMobileRef.current.style.opacity = 0)
        img0MRef.current && (img0MRef.current.style.opacity = 0)
        img1MRef.current && (img1MRef.current.style.opacity = 0)
        img2MRef.current && (img2MRef.current.style.opacity = 1)
        img3MRef.current && (img3MRef.current.style.opacity = 0)
        img4MRef.current && (img4MRef.current.style.opacity = 0)
        img5MRef.current && (img5MRef.current.style.opacity = 0)
        img6MRef.current && (img6MRef.current.style.opacity = 0)
        img7MRef.current && (img7MRef.current.style.opacity = 0)
        img8MRef.current && (img8MRef.current.style.opacity = 0)
      }
      if (selectedValue[0] === 1 && selectedValue[3] === 2) {
        img0Ref.current && (img0Ref.current.style.opacity = 0)
        img1Ref.current && (img1Ref.current.style.opacity = 0)
        img2Ref.current && (img2Ref.current.style.opacity = 0)
        img3Ref.current && (img3Ref.current.style.opacity = 1)
        img4Ref.current && (img4Ref.current.style.opacity = 0)
        img5Ref.current && (img5Ref.current.style.opacity = 0)
        img6Ref.current && (img6Ref.current.style.opacity = 0)
        img7Ref.current && (img7Ref.current.style.opacity = 0)
        img8Ref.current && (img8Ref.current.style.opacity = 0)
        imgFinalRef.current && (imgFinalRef.current.style.opacity = 0)
        imgFinalMobileRef.current && (imgFinalMobileRef.current.style.opacity = 0)
        img0MRef.current && (img0MRef.current.style.opacity = 0)
        img1MRef.current && (img1MRef.current.style.opacity = 0)
        img2MRef.current && (img2MRef.current.style.opacity = 0)
        img3MRef.current && (img3MRef.current.style.opacity = 1)
        img4MRef.current && (img4MRef.current.style.opacity = 0)
        img5MRef.current && (img5MRef.current.style.opacity = 0)
        img6MRef.current && (img6MRef.current.style.opacity = 0)
        img7MRef.current && (img7MRef.current.style.opacity = 0)
        img8MRef.current && (img8MRef.current.style.opacity = 0)
      }
      if (selectedValue[0] === 2 && selectedValue[3] === 1) {
        img0Ref.current && (img0Ref.current.style.opacity = 0)
        img1Ref.current && (img1Ref.current.style.opacity = 0)
        img2Ref.current && (img2Ref.current.style.opacity = 0)
        img3Ref.current && (img3Ref.current.style.opacity = 0)
        img4Ref.current && (img4Ref.current.style.opacity = 0)
        img5Ref.current && (img5Ref.current.style.opacity = 1)
        img6Ref.current && (img6Ref.current.style.opacity = 0)
        img7Ref.current && (img7Ref.current.style.opacity = 0)
        img8Ref.current && (img8Ref.current.style.opacity = 0)
        imgFinalRef.current && (imgFinalRef.current.style.opacity = 0)
        imgFinalMobileRef.current && (imgFinalMobileRef.current.style.opacity = 0)
        img0MRef.current && (img0MRef.current.style.opacity = 0)
        img1MRef.current && (img1MRef.current.style.opacity = 0)
        img2MRef.current && (img2MRef.current.style.opacity = 0)
        img3MRef.current && (img3MRef.current.style.opacity = 0)
        img4MRef.current && (img4MRef.current.style.opacity = 0)
        img5MRef.current && (img5MRef.current.style.opacity = 1)
        img6MRef.current && (img6MRef.current.style.opacity = 0)
        img7MRef.current && (img7MRef.current.style.opacity = 0)
        img8MRef.current && (img8MRef.current.style.opacity = 0)
      }
      if (selectedValue[0] === 2 && selectedValue[3] === 2) {
        img0Ref.current && (img0Ref.current.style.opacity = 0)
        img1Ref.current && (img1Ref.current.style.opacity = 0)
        img2Ref.current && (img2Ref.current.style.opacity = 0)
        img3Ref.current && (img3Ref.current.style.opacity = 0)
        img4Ref.current && (img4Ref.current.style.opacity = 0)
        img5Ref.current && (img5Ref.current.style.opacity = 0)
        img6Ref.current && (img6Ref.current.style.opacity = 1)
        img7Ref.current && (img7Ref.current.style.opacity = 0)
        img8Ref.current && (img8Ref.current.style.opacity = 0)
        imgFinalRef.current && (imgFinalRef.current.style.opacity = 0)
        imgFinalMobileRef.current && (imgFinalMobileRef.current.style.opacity = 0)
        img0MRef.current && (img0MRef.current.style.opacity = 0)
        img1MRef.current && (img1MRef.current.style.opacity = 0)
        img2MRef.current && (img2MRef.current.style.opacity = 0)
        img3MRef.current && (img3MRef.current.style.opacity = 0)
        img4MRef.current && (img4MRef.current.style.opacity = 0)
        img5MRef.current && (img5MRef.current.style.opacity = 0)
        img6MRef.current && (img6MRef.current.style.opacity = 1)
        img7MRef.current && (img7MRef.current.style.opacity = 0)
        img8MRef.current && (img8MRef.current.style.opacity = 0)
      }
      if (selectedValue[0] === 3 && selectedValue[3] === 1) {
        img0Ref.current && (img0Ref.current.style.opacity = 0)
        img1Ref.current && (img1Ref.current.style.opacity = 0)
        img2Ref.current && (img2Ref.current.style.opacity = 0)
        img3Ref.current && (img3Ref.current.style.opacity = 0)
        img4Ref.current && (img4Ref.current.style.opacity = 0)
        img5Ref.current && (img5Ref.current.style.opacity = 0)
        img6Ref.current && (img6Ref.current.style.opacity = 0)
        img7Ref.current && (img7Ref.current.style.opacity = 0)
        img8Ref.current && (img8Ref.current.style.opacity = 1)
        img0MRef.current && (img0MRef.current.style.opacity = 0)
        img1MRef.current && (img1MRef.current.style.opacity = 0)
        img2MRef.current && (img2MRef.current.style.opacity = 0)
        img3MRef.current && (img3MRef.current.style.opacity = 0)
        img4MRef.current && (img4MRef.current.style.opacity = 0)
        img5MRef.current && (img5MRef.current.style.opacity = 0)
        img6MRef.current && (img6MRef.current.style.opacity = 0)
        img7MRef.current && (img7MRef.current.style.opacity = 0)
        img8MRef.current && (img8MRef.current.style.opacity = 1)
        imgFinalRef.current && (imgFinalRef.current.style.opacity = 0)
        imgFinalMobileRef.current && (imgFinalMobileRef.current.style.opacity = 0)
      }
    }
    setHasChanged(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged])

  useEffect(() => {
    if (!selectedValue.includes("")) {
      img0Ref.current && (img0Ref.current.style.opacity = 0)
      img1Ref.current && (img1Ref.current.style.opacity = 0)
      img2Ref.current && (img2Ref.current.style.opacity = 0)
      img3Ref.current && (img3Ref.current.style.opacity = 0)
      img4Ref.current && (img4Ref.current.style.opacity = 0)
      img5Ref.current && (img5Ref.current.style.opacity = 0)
      img6Ref.current && (img6Ref.current.style.opacity = 0)
      img7Ref.current && (img7Ref.current.style.opacity = 0)
      img8Ref.current && (img8Ref.current.style.opacity = 0)
      img0MRef.current && (img0MRef.current.style.opacity = 0)
      img1MRef.current && (img1MRef.current.style.opacity = 0)
      img2MRef.current && (img2MRef.current.style.opacity = 0)
      img3MRef.current && (img3MRef.current.style.opacity = 0)
      img4MRef.current && (img4MRef.current.style.opacity = 0)
      img5MRef.current && (img5MRef.current.style.opacity = 0)
      img6MRef.current && (img6MRef.current.style.opacity = 0)
      img7MRef.current && (img7MRef.current.style.opacity = 0)
      img8MRef.current && (img8MRef.current.style.opacity = 0)
      imgFinalRef.current && (imgFinalRef.current.style.opacity = 1)
      imgFinalMobileRef.current && (imgFinalMobileRef.current.style.opacity = 1)
      backgroundRef.current.classList.add("final")
      backgroundMRef.current.classList.add("final")
      submitRef.current.classList.add("final")
    } else {
      backgroundRef.current.classList.remove("final")
      backgroundMRef.current.classList.remove("final")
      submitRef.current.classList.remove("final")
    }
  }, [selectedValue])


  return (
    <>
      <Wrapper>
        <div className="container" id="container">
          <div className="title-container">
            <div className="title" dangerouslySetInnerHTML={{ __html: data.title }}></div>
            <div className="subtitle" dangerouslySetInnerHTML={{ __html: data.subtitle }}></div>
            <div className="subtitle mobile" dangerouslySetInnerHTML={{ __html: data.subtitleM }}></div>
          </div>
          <div className="background-mobile-container" ref={backgroundMRef}>
            <div ref={img0MRef} className="background-mobile">
              <Img fluid={image0M} />
            </div>
            <div ref={img1MRef} className="background-mobile hidden">
              <Img fluid={image1M} />
            </div>
            <div ref={img2MRef} className="background-mobile hidden">
              <Img fluid={image2M} />
            </div>
            <div ref={img3MRef} className="background-mobile hidden">
              <Img fluid={image3M} />
            </div>
            <div ref={img4MRef} className="background-mobile hidden">
              <Img fluid={image4M} />
            </div>
            <div ref={img5MRef} className="background-mobile hidden">
              <Img fluid={image5M} />
            </div>
            <div ref={img6MRef} className="background-mobile hidden">
              <Img fluid={image6M} />
            </div>
            <div ref={img7MRef} className="background-mobile hidden">
              <Img fluid={image7M} />
            </div>
            <div ref={img8MRef} className="background-mobile hidden">
              <Img fluid={image8M} />
            </div>
          </div>
          <div className="form">
            <form id="turboram-form">
              {/* MODELO */}
              <div className={`input-wrapper ${hiddenWrapper[0] && `disabled`}`}>
                <select
                  className="input"
                  required
                  name={`modelo`}
                  id={`modelo`}
                  onChange={onUpdateItem(0)}
                /* disabled={disabledOption[0]} */
                >
                  <option defaultValue hidden aria-label="Empty"></option>
                  {data.form1.options1.map((ele, index) => (
                    <option key={ele} value={index}>
                      {ele}
                    </option>
                  ))}
                </select>
                <label htmlFor={`modelo`} className="label">
                  {data.form1.title}
                </label>
              </div>
              {/* DIMENSÃO TABULEIRO */}
              <div className={`input-wrapper ${hiddenWrapper[1] && `disabled`}`}>
                <select
                  className="input"
                  required
                  name={`input1`}
                  id={`input1`}
                  onChange={onUpdateItem(1)}
                /* disabled={disabledOption[1]} */
                >
                  <option defaultValue hidden aria-label="Empty"></option>
                  {optionsFilter[1] === 1
                    ? data.form2.options1.map((ele, index) => (
                      <option key={ele} value={index}>
                        {ele}
                      </option>
                    ))
                    : optionsFilter[1] === 2
                      ? data.form2.options2.map((ele, index) => (
                        <option key={ele} value={index}>
                          {ele}
                        </option>
                      ))
                      : data.form2.options3.map((ele, index) => (
                        <option key={ele} value={index}>
                          {ele}
                        </option>
                      ))}
                </select>
                <label htmlFor={`input1`} className="label">
                  {data.form2.title}
                </label>
              </div>
              {/* QUANTIDADE TABULEIROS */}
              <div className={`input-wrapper ${hiddenWrapper[2] && `disabled`}`}>
                <select
                  className="input"
                  required
                  name={`input2`}
                  id={`input2`}
                  onChange={onUpdateItem(2)}
                /* disabled={disabledOption[2]} */
                >
                  <option defaultValue hidden aria-label="Empty"></option>
                  {optionsFilter[2] === 1
                    ? data.form3.options1.map((ele, index) => (
                      <option key={ele} value={index}>
                        {ele}
                      </option>
                    ))
                    : optionsFilter[2] === 2
                      ? data.form3.options2.map((ele, index) => (
                        <option key={ele} value={index}>
                          {ele}
                        </option>
                      ))
                      : optionsFilter[2] === 3
                        ? data.form3.options3.map((ele, index) => (
                          <option key={ele} value={index}>
                            {ele}
                          </option>
                        ))
                        : optionsFilter[2] === 4
                          ? data.form3.options4.map((ele, index) => (
                            <option key={ele} value={index}>
                              {ele}
                            </option>
                          ))
                          : optionsFilter[2] === 5
                            ? data.form3.options5.map((ele, index) => (
                              <option key={ele} value={index}>
                                {ele}
                              </option>
                            ))
                            : optionsFilter[2] === 6
                              ? data.form3.options6.map((ele, index) => (
                                <option key={ele} value={index}>
                                  {ele}
                                </option>
                              ))
                              : optionsFilter[2] === 7
                                ? data.form3.options7.map((ele, index) => (
                                  <option key={ele} value={index}>
                                    {ele}
                                  </option>
                                ))
                                : data.form3.options8.map((ele, index) => (
                                  <option key={ele} value={index}>
                                    {ele}
                                  </option>
                                ))}
                </select>
                <label htmlFor={`input2`} className="label">
                  {data.form3.title}
                </label>
              </div>
              {/* FECHO */}
              <div className={`input-wrapper ${hiddenWrapper[3] && `disabled`}`}>
                <select
                  className="input"
                  required
                  name={`input3`}
                  id={`input3`}
                  onChange={onUpdateItem(3)}
                /* disabled={disabledOption[3]} */
                >
                  <option defaultValue hidden aria-label="Empty"></option>
                  {optionsFilter[3] === 1
                    ? data.form4.options1.map((ele, index) => (
                      <option key={ele} value={index}>
                        {ele}
                      </option>
                    ))
                    : data.form4.options2.map((ele, index) => (
                      <option key={ele} value={index}>
                        {ele}
                      </option>
                    ))}
                </select>
                <label htmlFor={`input3`} className="label">
                  {data.form4.title}
                </label>
              </div>
              {/* AUTOLAVAGEM */}
              <div className={`input-wrapper ${hiddenWrapper[4] && `disabled`}`}>
                <select
                  className="input"
                  required
                  name={`input4`}
                  id={`input4`}
                  onChange={onUpdateItem(4)}
                /* disabled={disabledOption[4]} */
                >
                  <option defaultValue hidden aria-label="Empty"></option>
                  {optionsFilter[4] === 1
                    ? data.form5.options1.map((ele, index) => (
                      <option key={ele} value={index}>
                        {ele}
                      </option>
                    ))
                    : optionsFilter[4] === 2
                      ? data.form5.options2.map((ele, index) => (
                        <option key={ele} value={index}>
                          {ele}
                        </option>
                      ))
                      : data.form5.options3.map((ele, index) => (
                        <option key={ele} value={index}>
                          {ele}
                        </option>
                      ))}
                </select>
                <label htmlFor={`input4`} className="label">
                  {data.form5.title}
                </label>
              </div>
              {/* CUPULA */}
              <div className={`input-wrapper ${hiddenWrapper[5] && `disabled`}`}>
                <select
                  className="input"
                  required
                  name={`input5`}
                  id={`input5`}
                  onChange={onUpdateItem(5)}
                /* disabled={disabledOption[5]} */
                >
                  <option defaultValue hidden aria-label="Empty"></option>
                  {optionsFilter[5] === 1
                    ? data.form6.options1.map((ele, index) => (
                      <option key={ele} value={index}>
                        {ele}
                      </option>
                    ))
                    : data.form6.options2.map((ele, index) => (
                      <option key={ele} value={index}>
                        {ele}
                      </option>
                    ))}
                </select>
                <label htmlFor={`input5`} className="label">
                  {data.form6.title}
                </label>
              </div>
              {/* BASE */}
              <div className={`input-wrapper ${hiddenWrapper[6] && `disabled`}`}>
                <select
                  className="input"
                  required
                  name={`input6`}
                  id={`input6`}
                  onChange={onUpdateItem(6)}
                /* disabled={disabledOption[6]} */
                >
                  <option defaultValue hidden aria-label="Empty"></option>
                  {optionsFilter[6] === 1
                    ? data.form7.options1.map((ele, index) => (
                      <option key={ele} value={index}>
                        {ele}
                      </option>
                    ))
                    : data.form7.options2.map((ele, index) => (
                      <option key={ele} value={index}>
                        {ele}
                      </option>
                    ))}
                </select>
                <label htmlFor={`input6`} className="label">
                  {data.form7.title}
                </label>
              </div>
              {/* EXTRAS */}
              <div className={`input-wrapper ${hiddenWrapper[7] && `disabled`}`}>
                <select
                  className="input"
                  required
                  name={`input7`}
                  id={`input7`}
                  onChange={onUpdateItem(7)}
                /* disabled={disabledOption[7]} */
                >
                  <option defaultValue hidden aria-label="Empty"></option>
                  {optionsFilter[7] === 1
                    ? data.form8.options1.map((ele, index) => (
                      <option key={ele} value={index}>
                        {ele}
                      </option>
                    ))
                    : optionsFilter[7] === 2
                      ? data.form8.options2.map((ele, index) => (
                        <option key={ele} value={index}>
                          {ele}
                        </option>
                      ))
                      : data.form8.options3.map((ele, index) => (
                        <option key={ele} value={index}>
                          {ele}
                        </option>
                      ))}
                </select>
                <label htmlFor={`input7`} className="label">
                  {data.form8.title}
                </label>
              </div>
              <div className="warning" ref={warningRef}>
                {data.required}
              </div>
              <div ref={imgFinalMobileRef} className="final-image mobile-visible hidden">
                <ReactSVG
                  src={`/configurador/${allStates.oven}/0${allStates.value1}_0${allStates.value2}_0${allStates.value3}_0${allStates.value4}_0${allStates.value5}_01_0${allStates.value7}_0${allStates.value8}.svg`}
                  alt={allStates.oven}
                />
              </div>
              <div className="arrow-container">
                <div className="arrow" id="left-arrow" role="presentation" onClick={handleReset}>
                  <ArrowLeft />
                  <p>{data.reset}</p>
                </div>
                <div className="arrow" id="right-arrow" role="presentation" onClick={handleSubmit} ref={submitRef}>
                  <p>{data.submit}</p>
                  <ArrowRight />
                </div>
              </div>
            </form>
            <div className="background-container" ref={backgroundRef}>
              <div ref={img0Ref} className="background">
                <Img fluid={image0} />
              </div>
              <div ref={img1Ref} className="background hidden">
                <Img fluid={image1} />
              </div>
              <div ref={img2Ref} className="background hidden">
                <Img fluid={image2} />
              </div>
              <div ref={img3Ref} className="background hidden">
                <Img fluid={image3} />
              </div>
              <div ref={img4Ref} className="background hidden">
                <Img fluid={image4} />
              </div>
              <div ref={img5Ref} className="background hidden">
                <Img fluid={image5} />
              </div>
              <div ref={img6Ref} className="background hidden">
                <Img fluid={image6} />
              </div>
              <div ref={img7Ref} className="background hidden">
                <Img fluid={image7} />
              </div>
              <div ref={img8Ref} className="background hidden">
                <Img fluid={image8} />
              </div>
            </div>
            <div className="image mobile-hidden" ref={imgFinalRef} id="image3">
              <ReactSVG
                src={`/configurador/${allStates.oven}/0${allStates.value1}_0${allStates.value2}_0${allStates.value3}_0${allStates.value4}_0${allStates.value5}_01_0${allStates.value7}_0${allStates.value8}.svg`}
                alt={allStates.oven}
              />
            </div>
          </div>
        </div>
      </Wrapper>
      <WrapperForm visible={showForm}>
        <Form info={dataForm} id="contact-form" context={{ ...allStates }} strings={strings} oven='Turboram' />
      </WrapperForm>
    </>
  )
}

export default Configurador

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  margin-top: 150px;
  padding-bottom: 150px;
  position: relative;
  z-index: 3;
  background-color: black;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 75px;
    padding-bottom: 75px;
  }

  .background-container {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    @media (max-width: 768px) {
      display: none;

      &.final {
        display: none;
      }
    }
  }
  .background {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    transition: all 0.3s ${ease.out};

    @media (max-width: 768px) {
      width: 200%;
      transform: translateX(-50%);
    }
  }

  .background-mobile-container {
    display: none;
    width: 100%;
    position: relative;

    &.final {
      display: none;
    }

    @media (max-width: 768px) {
      display: block;
      margin-bottom: 1.5rem;
    }
  }

  .background-mobile {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:first-child {
      position: static;
      top: unset;
      left: unset;
      transform: unset;
    }
  }

  .container {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    justify-items: center;
    align-items: center;
    z-index: 3;
    grid-template-areas:
      "title ."
      "form form";

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      grid-template-columns: unset;
      grid-template-rows: unset;
      grid-template-areas: unset;
      padding: 0 15%;
    }
  }

  .title-container {
    grid-area: title;

    width: min(35vw, 100%);
    margin: 0 auto;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .title {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 4rem;
    color: ${color.white};
    text-transform: uppercase;

    @media (max-width: 900px) {
      font-size: 3rem;
    }

    @media (max-width: 768px) {
      font-size: 2.25rem;
      text-align: center;
    }
  }

  .subtitle {
    font-family: "Financier", sans-serif;
    font-weight: 200;
    font-style: italic;
    font-size: 5rem;
    line-height: 1.25;
    color: ${color.red};
    margin-bottom: 6rem;

    @media (max-width: 900px) {
      font-size: 4rem;
      margin-bottom: 4rem;
    }

    @media (max-width: 768px) {
      font-size: 2rem;
      text-align: center;
      margin-bottom: 2rem;
      display: none;
    }
  }

  .mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .form {
    grid-area: form;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    position: relative;
    width: 100%;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  .form form {
    z-index: 3;
    width: min(35vw, 100%);
    margin: 0 auto;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .input-wrapper {
    position: relative;
    margin-top: 2vw;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 4vw;
    }
  }

  .input {
    width: 100%;
    border: 0;
    outline: 0;
    min-height: 44px;
    background-color: ${color.grey};
    color: ${color.greyDark};
    ${font.robotoMedium};
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    padding: 20px 30px 6px;
    box-shadow: none;

    @media (max-width: 768px) {
      padding: 0 30px;
    }

    &::placeholder {
      color: transparent;
      user-select: none;
    }
  }
  .input {
    &:focus,
    &:not(:placeholder-shown) {
      & + .label {
        transform: translateY(-3px) scale(0.75);
        opacity: 0.75;
      }
    }
  }
  .input:valid ~ .label {
    transform: translateY(-3px) scale(0.75);
    opacity: 0.75;
  }

  .label {
    position: absolute;
    left: 30px;
    top: 0;
    color: ${color.red};
    ${font.robotoBlack};
    font-size: 16px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    transform-origin: left;
    transform: translateY(8px) scale(1);
    pointer-events: none;
    transition: all 0.15s ${ease.out};

    @media (max-width: 768px) {
      position: unset;
      line-height: 25px;
    }
  }

  .input-wrapper select {
    border: 0;
    outline: 0;
    height: 44px;
    border-bottom: 1px solid ${color.white};
    ${font.robotoMedium};
    font-size: 1.4rem;
    color: ${color.white};
    -webkit-appearance: none;
  }

  .input-wrapper option {
    margin: 5px 0;
    height: 44px;
    background: #000;
    ${font.robotoMedium};

    :checked {
      box-shadow: 0 0 10px 100px red inset;
    }
  }

  .input {
    -webkit-appearance: none;
    border-radius: 0px;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
      no-repeat 0 50%;
  }

  .arrow-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3vw;

    @media (max-width: 768px) {
      margin-top: 2rem;
    }
  }

  .arrow {
    --color: ${color.grey};
    --color-hover: ${color.white};
    --color-final: ${color.red};

    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    color: var(--color);
    fill: var(--color);
    display: flex;
    align-items: center;
    transition: all 0.15s ${ease.out};

    &.final > *,
    &.final:hover > * {
      color: var(--color-final);
      fill: var(--color-final);
    }

    svg {
      transform: translateY(-10%);
    }

    &:hover > * {
      color: var(--color-hover);
      fill: var(--color-hover);
    }

    & > *:last-child {
      margin-left: 1em;
    }

    @media (max-width: 768px) {
      font-size: 1.2rem;

      svg {
        width: 1.5ch;
        transform: unset;
      }
    }
  }

  .warning {
    font-family: "Roboto", sans-serif;
    color: ${color.red};
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 1rem;
    visibility: hidden;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .final-image {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40%;
  }

  .hidden {
    opacity: 0;
  }

  .disabled {
    display: none;
  }

  .mobile-hidden {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .mobile-visible {
    @media (max-width: 768px) {
      display: block;
    }
    display: none;
  }

  .final {
    margin-bottom: 0;
  }

  .final-image {
    margin: 0 auto 3rem auto;
    width: 100%;
    position: relative;
    opacity: 0;
    z-index: -1;
    & > div {
      display: block;
      text-align: center;
    }
    & svg {
      /* max-width: 30vw; */
      max-height: 50vw;
    }

    svg .cls-1,
    svg .cls-2,
    svg .cls-3,
    svg .cls-4 {
      fill: white !important;
      stroke: white !important;
    }
  }
  .image {
    width: 100%;
    position: relative;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.15s ${ease.out};

    & > div {
      display: block;
      text-align: center;
    }
    & svg {
      margin: 0 auto;
      /* max-width: 12.5vw; */
      max-height: 30vw;

      @media (max-width: 1440px) {
        /* max-width: 15vw; */
      }
    }

    svg .cls-1,
    svg .cls-2,
    svg .cls-3,
    svg .cls-4 {
      fill: white !important;
      stroke: white !important;
    }
  }
`

const WrapperForm = styled.section`
  display: ${props => (props.visible ? "block" : "none")};
`
