import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../layout"

// Components
import SEO from "../components/SEO"
import Configurator from "../components/Configurador/Turboram"
import { useTranslation, I18nextContext } from "gatsby-plugin-react-i18next"

const Teste = ({ data, location }) => {
  const { t } = useTranslation()
  const activeLanguage = React.useContext(I18nextContext).language

  const [configData, setConfigData] = useState(data.pt.pt)
  const [formData, setFormData] = useState(data.ptform.edges[0].node.pt)

  useEffect(() => {
    switch (activeLanguage) {
      case "pt":
        setConfigData(data.pt.pt)
        setFormData(data.ptform.edges[0].node.pt)
        break
      case "en":
        setConfigData(data.en.en)
        setFormData(data.enform.edges[0].node.en)
        break
      case "es":
        setConfigData(data.es.es)
        setFormData(data.esform.edges[0].node.es)
        break
      case "mx":
        setConfigData(data.mx.mx)
        setFormData(data.mxform.edges[0].node.mx)
        break
      case "fr":
        setConfigData(data.fr.fr)
        setFormData(data.frform.edges[0].node.fr)
        break
      default:
        setConfigData(data.pt.pt)
        break
    }
  }, [
    activeLanguage,
    data.pt.pt,
    data.en.en,
    data.es.es,
    data.mx.mx,
    data.fr.fr,
    data.ptform.edges,
    data.enform.edges,
    data.esform.edges,
    data.mxform.edges,
    data.frform.edges,
  ])

  const infoConfig = configData
  const infoForm = formData
  const formStrings = configData.formString


  return (
    <Layout
      location={location}
      footer={t("footer", { returnObjects: true })}
      header={t("header", { returnObjects: true })}
      hideBr
    >
      <SEO title="Turboram" bodyClass={"configurador"} 
        domain={location?.hostname}></SEO>
      <Configurator id="configurator-turboram" data={infoConfig} dataForm={infoForm} strings={formStrings} />
    </Layout>
  )
}

export default Teste

export const ConfiguradorTurboramPT = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["rotoram", "footer", "header", "form"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pt: configuradorJson(name: { eq: "Turboram" }) {
      pt {
        title
        subtitle
        subtitleM
        form1 {
          title
          options1
        }
        form2 {
          title
          options1
          options2
          options3
        }
        form3 {
          title
          options1
          options2
          options3
          options4
          options5
          options6
        }
        form4 {
          title
          options1
          options2
        }
        form5 {
          title
          options1
          options2
          options3
        }
        form6 {
          title
          options1
        }
        form7 {
          title
          options1
          options2
        }
        form8 {
          title
          options1
          options2
          options3
        }
        image0 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image1 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image4 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image5 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image6 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image7 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image8 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image0M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image1M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image2M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image3M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image4M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image5M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image6M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image7M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image8M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        reset
        submit
        required
        formString {
          config
          by
          clientData
          configData
          image
          message
          name
          email
          tel
          country
          postal
        }
      }
    }
    en: configuradorJson(name: { eq: "Turboram" }) {
      en {
        title
        subtitle
        subtitleM
        form1 {
          title
          options1
        }
        form2 {
          title
          options1
          options2
          options3
        }
        form3 {
          title
          options1
          options2
          options3
          options4
          options5
          options6
        }
        form4 {
          title
          options1
          options2
        }
        form5 {
          title
          options1
          options2
          options3
        }
        form6 {
          title
          options1
        }
        form7 {
          title
          options1
          options2
        }
        form8 {
          title
          options1
          options2
          options3
        }
        image0 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image1 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image4 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image5 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image6 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image7 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image8 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image0M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image1M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image2M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image3M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image4M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image5M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image6M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image7M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image8M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        reset
        submit
        required
        formString {
          config
          by
          clientData
          configData
          image
          message
          name
          email
          tel
          country
          postal
        }
      }
    }
    es: configuradorJson(name: { eq: "Turboram" }) {
      es {
        title
        subtitle
        subtitleM
        form1 {
          title
          options1
        }
        form2 {
          title
          options1
          options2
          options3
        }
        form3 {
          title
          options1
          options2
          options3
          options4
          options5
          options6
        }
        form4 {
          title
          options1
          options2
        }
        form5 {
          title
          options1
          options2
          options3
        }
        form6 {
          title
          options1
        }
        form7 {
          title
          options1
          options2
        }
        form8 {
          title
          options1
          options2
          options3
        }
        image0 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image1 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image4 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image5 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image6 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image7 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image8 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image0M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image1M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image2M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image3M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image4M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image5M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image6M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image7M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image8M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        reset
        submit
        required
        formString {
          config
          by
          clientData
          configData
          image
          message
          name
          email
          tel
          country
          postal
        }
      }
    }
    mx: configuradorJson(name: { eq: "Turboram" }) {
      mx {
        title
        subtitle
        subtitleM
        form1 {
          title
          options1
        }
        form2 {
          title
          options1
          options2
          options3
        }
        form3 {
          title
          options1
          options2
          options3
          options4
          options5
          options6
        }
        form4 {
          title
          options1
          options2
        }
        form5 {
          title
          options1
          options2
          options3
        }
        form6 {
          title
          options1
        }
        form7 {
          title
          options1
          options2
        }
        form8 {
          title
          options1
          options2
          options3
        }
        image0 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image1 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image4 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image5 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image6 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image7 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image8 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image0M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image1M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image2M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image3M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image4M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image5M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image6M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image7M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image8M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        reset
        submit
        required
        formString {
          config
          by
          clientData
          configData
          image
          message
          name
          email
          tel
          country
          postal
        }
      }
    }
    fr: configuradorJson(name: { eq: "Turboram" }) {
      fr {
        title
        subtitle
        subtitleM
        form1 {
          title
          options1
        }
        form2 {
          title
          options1
          options2
          options3
        }
        form3 {
          title
          options1
          options2
          options3
          options4
          options5
          options6
        }
        form4 {
          title
          options1
          options2
        }
        form5 {
          title
          options1
          options2
          options3
        }
        form6 {
          title
          options1
        }
        form7 {
          title
          options1
          options2
        }
        form8 {
          title
          options1
          options2
          options3
        }
        image0 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image1 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image4 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image5 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image6 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image7 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image8 {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image0M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image1M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image2M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image3M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image4M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image5M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image6M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image7M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        image8M {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        reset
        submit
        required
        formString {
          config
          by
          clientData
          configData
          image
          message
          name
          email
          tel
          country
          postal
        }
      }
    }
    ptform: allFormJson {
      edges {
        node {
          pt {
            field1
            field2
            field3
            field4
            field5
            field6
            field7
            field8
            field9
            field10
            button1
            button2
            button3
            dropdown1
            dropdown2
            dropdown3
            dropdown4
            text1
            text2
            text3
            submit
            call
            confirmation1
            confirmation2
            error1
            error2
            assistance
            other
          }
        }
      }
    }
    enform: allFormJson {
      edges {
        node {
          en {
            field1
            field2
            field3
            field4
            field5
            field6
            field7
            field8
            field9
            field10
            button1
            button2
            button3
            dropdown1
            dropdown2
            dropdown3
            dropdown4
            text1
            text2
            text3
            submit
            call
            confirmation1
            confirmation2
            error1
            error2
            assistance
            other
          }
        }
      }
    }
    esform: allFormJson {
      edges {
        node {
          es {
            field1
            field2
            field3
            field4
            field5
            field6
            field7
            field8
            field9
            field10
            button1
            button2
            button3
            dropdown1
            dropdown2
            dropdown3
            dropdown4
            text1
            text2
            text3
            submit
            call
            confirmation1
            confirmation2
            error1
            error2
            assistance
            other
          }
        }
      }
    }
    mxform: allFormJson {
      edges {
        node {
          mx {
            field1
            field2
            field3
            field4
            field5
            field6
            field7
            field8
            field9
            field10
            button1
            button2
            button3
            dropdown1
            dropdown2
            dropdown3
            dropdown4
            text1
            text2
            text3
            submit
            call
            confirmation1
            confirmation2
            error1
            error2
            assistance
            other
          }
        }
      }
    }
    frform: allFormJson {
      edges {
        node {
          fr {
            field1
            field2
            field3
            field4
            field5
            field6
            field7
            field8
            field9
            field10
            button1
            button2
            button3
            dropdown1
            dropdown2
            dropdown3
            dropdown4
            text1
            text2
            text3
            submit
            call
            confirmation1
            confirmation2
            error1
            error2
            assistance
            other
          }
        }
      }
    }
  }
`
